import React, { useState } from "react";
import {
  List,
  ListItem,
  Button,
  Typography,
  Stack,
  Box,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Popover,
} from "../../../ui/core";
import { useFormatters, useCopyToClipboard, useToast } from "../../../hooks";
import { useDispatch } from "react-redux";
import {
  cancelRide as apiCancelRide,
  reinstateTripRide,
  pickUpRide,
  dropOffRide,
} from "../../../redux/Trips/actions";
import { RideStatus } from "../../../models/Rides";

import UserCard from "./UserCard";
import {
  CheckCircleIcon,
  CloseIcon,
  CancelIcon,
  MoreVertIcon,
  ArrowForwardIcon,
  CircleIcon,
  ContentCopyIcon,
} from "../../../ui/icons";

export const RideStatusChip = ({ status }) => {
  switch (status) {
    case RideStatus.processing:
      return (
        <Chip
          icon={<CircleIcon sx={{ fontSize: 16 }} />}
          color="warning"
          label="Processing"
        />
      );
    case RideStatus.finalizing_details:
      return (
        <Chip
          icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
          color="warning"
          label="Finalizing Details"
        />
      );
    case RideStatus.accepted:
      return (
        <Chip
          icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
          color="info"
          label="Accepted"
        />
      );
    case RideStatus.en_route:
      return (
        <Chip
          icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
          color="info"
          label="Picked-up"
        />
      );
    case RideStatus.completed:
      return (
        <Chip
          icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
          color="info"
          label="Completed"
        />
      );
    case RideStatus.cancelled:
      return (
        <Chip
          icon={<CancelIcon sx={{ fontSize: 16 }} />}
          color="cancelled"
          label="Cancelled"
        />
      );
  }
};

const DepartureTimes = ({ ride, trip }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const f = useFormatters();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-describedby="departure_time_details" onClick={handleOpen}>
        <Stack direction="row" spacing="8px">
          <Typography color="text.secondary" variant="body2">
            Departure time
          </Typography>
          <Typography>
            {f.date.toTimezoneLocaleString(
              ride.departureTime,
              trip.originCity.timeZone,
              f.date.DateFormats.TIME_SIMPLE,
            )}
          </Typography>
        </Stack>
      </Button>
      <Popover
        id="departure_time_details"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p="24px" width="375px">
          <Typography variant="h5" mb="16px">
            Departure Time Details
          </Typography>
          {ride.departureTimeHistory &&
            ride.departureTimeHistory.length > 0 && (
              <>
                <Typography variant="body2" fontWeight={700} mb="8px">
                  Departure Time History
                </Typography>
                {ride.departureTimeHistory.map((history, index) => (
                  <Box key={index} mb="8px">
                    <Typography variant="body2">
                      Past Departure Time:{" "}
                      {f.date.toTimezoneLocaleString(
                        history.pastDeptTime,
                        trip.originCity.timeZone,
                        f.date.DateFormats.DATETIME_FULL,
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Changed at:{" "}
                      {f.date.toTimezoneLocaleString(
                        history.timestamp,
                        trip.originCity.timeZone,
                        f.date.DateFormats.DATETIME_FULL,
                      )}
                    </Typography>
                  </Box>
                ))}
              </>
            )}
        </Box>
      </Popover>
    </div>
  );
};

const RideFare = ({ ride }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const f = useFormatters();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-describedby="price_breakdown" onClick={handleOpen}>
        <Stack direction="row" spacing="8px">
          <Typography color="text.secondary" variant="body2">
            Trip price
          </Typography>
          <Typography>{f.number.currency(ride.fare.finalAmount)}</Typography>
        </Stack>
      </Button>
      <Popover
        id="price_breakdown"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p="24px" width="375px">
          <Typography variant="h5" mb="16px">
            Price
          </Typography>
          {(ride.fare.priceLines || []).map((priceLine, idx) => (
            <Box
              key={idx}
              display="flex"
              justifyContent="space-between"
              mb="8px"
            >
              <Typography color="text.secondary" fontWeight={400}>
                {priceLine.item}
              </Typography>
              <Typography
                color={priceLine.amount < 0 ? "success.main" : "text.secondary"}
                fontWeight={priceLine.amount < 0 ? 700 : 400}
              >
                {priceLine.displayAmount}
              </Typography>
            </Box>
          ))}
          <Divider sx={{ marginTop: "16px" }} />
          <Box display="flex" justifyContent="space-between" mt="16px">
            <Typography fontWeight={700}>Total (USD)</Typography>
            <Typography fontWeight={700}>
              {ride.fare ? f.number.currency(ride.fare.finalAmount) : "-"}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

const RideItem = ({ ride, trip }) => {
  const f = useFormatters();
  const c = useCopyToClipboard();
  const t = useToast();
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const copyIdToClipboard = () => {
    c.copy(ride.id);
    t.success("Ride id copied to clipboard");
  };

  const cancelRide = async () => {
    try {
      setWaiting(true);
      await apiCancelRide(ride.id)(dispatch);
      setWaiting(false);
      setAnchorEl(null);
    } catch (err) {
      t.error("There was an error canceling this ride");
    }
  };

  const reinstateRide = async () => {
    try {
      setWaiting(true);
      await reinstateTripRide(trip.id, ride.id)(dispatch);
      setWaiting(false);
      setAnchorEl(null);
    } catch (err) {
      t.error("There was an error re-instating this ride");
    }
  };

  const pickUpRider = async () => {
    try {
      setWaiting(true);
      await pickUpRide(trip.id, ride.id)(dispatch);
      setWaiting(false);
      setAnchorEl(null);
    } catch (err) {
      t.error("There was an error marking this ride as picked-up");
    }
  };

  const dropOffRider = async () => {
    try {
      setWaiting(true);
      await dropOffRide(trip.id, ride.id)(dispatch);
      setWaiting(false);
      setAnchorEl(null);
    } catch (err) {
      t.error("There was an error marking this ride as dropped-off");
    }
  };

  const actions = [];

  if (ride.status === RideStatus.accepted) {
    actions.push(
      <MenuItem key="pick-up" disabled={waiting} onClick={pickUpRider}>
        {waiting && <CircularProgress size={16} />}
        <Typography color="info.main" ml="5px">
          Mark as picked-up
        </Typography>
      </MenuItem>,
    );
  }

  if (ride.status === RideStatus.en_route) {
    actions.push(
      <MenuItem key="drop-off" disabled={waiting} onClick={dropOffRider}>
        {waiting && <CircularProgress size={16} />}
        <Typography color="info.main" ml="5px">
          Mark as dropped-off
        </Typography>
      </MenuItem>,
    );
  }

  if (![RideStatus.completed, RideStatus.cancelled].includes(ride.status)) {
    actions.push(
      <MenuItem
        key="cancel"
        color="error"
        disabled={waiting}
        onClick={cancelRide}
      >
        {!waiting && <CloseIcon color="error" />}
        {waiting && <CircularProgress size={16} />}
        <Typography color="error" ml="5px">
          Cancel rider
        </Typography>
      </MenuItem>,
    );
  }
  if (ride.status === RideStatus.cancelled) {
    actions.push(
      <MenuItem key="reinstate" disabled={waiting} onClick={reinstateRide}>
        {!waiting && <ArrowForwardIcon color="info" sx={{ fontSize: 16 }} />}
        {waiting && <CircularProgress size={16} />}
        <Typography color="info.main" ml="5px">
          Reinstate rider
        </Typography>
      </MenuItem>,
    );
  }

  return (
    <ListItem>
      <Box display="flex" flexDirection="column" flex={1} py="4px">
        <Stack
          direction="row"
          spacing="100px"
          alignItems="center"
          justifyContent="space-between"
          py="8px"
          px="8px"
        >
          <Stack direction="row" alignItems="center" spacing="16px">
            <Button href={`/users/${ride.rider.id}`} target="_blank">
              <UserCard user={ride.rider} />
            </Button>
            <RideStatusChip status={ride.status} />
          </Stack>
          {actions.length > 0 && (
            <>
              <IconButton
                id={`${ride.id}-menu-button`}
                aria-controls={menuOpen ? `${ride.id}-menu` : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`${ride.id}-menu`}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ "aria-labelledby": `${ride.id}-menu-button` }}
              >
                {actions}
              </Menu>
            </>
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing="48px"
          py="8px"
          px="8px"
        >
          <Stack direction="row" spacing="8px">
            <Typography color="text.secondary" variant="body2">
              Ride
            </Typography>
            <Typography>{ride.id}</Typography>
            <IconButton size="small" onClick={copyIdToClipboard}>
              <ContentCopyIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing="48px"
          py="8px"
          px="8px"
        >
          {ride.departureTime && <DepartureTimes ride={ride} trip={trip} />}
          {ride.fare && <RideFare ride={ride} />}
          <Stack direction="row" spacing="8px">
            <Typography color="text.secondary" variant="body2">
              Contact
            </Typography>
            <Typography>
              {f.phone.toInternational(ride.rider.phoneNumber)}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing="8px" py="8px" px="8px">
          <Box display="flex" maxWidth="50%">
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{ whiteSpace: "nowrap", marginRight: "8px" }}
            >
              Pick-up ({ride.pickUp ? ride.pickUp.type : ""})
            </Typography>
            <Typography>
              {ride.pickUp ? ride.pickUp.name || ride.pickUp.address : ""}
            </Typography>
          </Box>
          <Typography> ➔ </Typography>
          <Box display="flex">
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{ whiteSpace: "nowrap", marginRight: "8px" }}
            >
              Drop-off ({ride.dropOff ? ride.dropOff.type : ""})
            </Typography>
            <Typography>
              {ride.dropOff ? ride.dropOff.name || ride.dropOff.address : ""}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </ListItem>
  );
};

const RidersList = ({ trip, rides }) => {
  return (
    <List>
      {rides.map((r, idx) => (
        <React.Fragment key={r.id}>
          <RideItem ride={r} trip={trip} />
          {idx < rides.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default RidersList;
