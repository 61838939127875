import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Button,
  InputBase,
} from '../../../../ui/core';
import { CloseIcon} from '../../../../ui/icons'
import { cancelTripDriver, cancelTripDriverEstimate } from '../../../../redux/Trips/actions';

const CANCEL_REASONS = [
 'Can’t make it to the location on time',
 'Late Return Ride',
 'Family or Health Emergency',
 'Vehicle Difficulties',
 'Passenger Issue',
 'Don’t Want to Do the Ride'
];

const CANCEL_PENALTIES = [
  { value: 0, label: 'No Penalty' }
];

const ActionCancel = ({ trip, waiting, onWaitingChange, onClose }) => {
  const dispatch = useDispatch();
  const [cancelEstimate, setCancelEstimate] = useState(null);
  const [reason, setReason] = useState(CANCEL_REASONS[0]);
  const [otherReason, setOtherReason] = useState("");
  const otherReasonInputRef = useRef(null);
  const [penalty, setPenalty] = useState(CANCEL_PENALTIES[0].value);

  const getCancelEstimate = async () => {
    onWaitingChange(true);
    const { estimate } = await cancelTripDriverEstimate(trip.id)(dispatch);
    setCancelEstimate(estimate);
    setReason(estimate.reason);
    setPenalty(estimate.penalty);
  };

  const cancelDriver = async () => {
    onWaitingChange(true);
    await cancelTripDriver(
      trip.id,
      { reason: `Admin: ` + (reason !== "Other" ? reason : otherReason), penalty }
    )(dispatch);
    onWaitingChange(false);
    setCancelEstimate(null);
    onClose();
  };

  const giveUp = () => {
    onWaitingChange(false);
    setCancelEstimate(null);
    onClose();
  };

  const reasons = [
    ...CANCEL_REASONS,
  ];

  const penalties = [
    ...(cancelEstimate ? [{ value: cancelEstimate.penalty, label: `Deducted ${cancelEstimate.penalty} points`}] : []),
    ...CANCEL_PENALTIES,
  ];

  useEffect(() => {
    if (reason === "Other" && otherReasonInputRef.current) {
      otherReasonInputRef.current.focus();
    }
  }, [reason]);

  return (<>
    <MenuItem
      disabled={waiting}
      onClick={getCancelEstimate}
    >
      { !waiting && <CloseIcon color="error" /> }
      { waiting && <CircularProgress size={16} />}
      <Typography color="error" ml="5px">Cancel driver</Typography>
    </MenuItem>
    <Dialog open={!!cancelEstimate} onClose={giveUp}>
      <DialogTitle sx={{ padding: "12px 16px", color: "text.secondary", fontSize: 16 }}>Fill out the cancellation request</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box sx={{ backgroundColor: "divider", padding: "2px 16px" }}>
          <Typography color="text.secondary" variant="body2">Reason</Typography>
        </Box>
        <Box sx={{ padding: "10px 16px" }}>
          {
            <FormControl>
            <RadioGroup value={reason} onChange={e => setReason(e.target.value)}>
              {
                reasons.map((reason) => <FormControlLabel
                  key={reason}
                  value={reason}
                  control={<Radio />}
                  label={reason}
                />)
              }
              <FormControlLabel
                control={<Radio />}
                label={<InputBase
                    inputRef={otherReasonInputRef}
                    placeholder="Other"
                    disabled={reason !== "Other"}
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                  />
                }
                value="Other"
              />
            </RadioGroup>
          </FormControl>
          }
        </Box>
        <Box sx={{ backgroundColor: "divider", padding: "2px 16px" }}>
          <Typography color="text.secondary" variant="body2">Penalty</Typography>
        </Box>
        <Box sx={{ padding: "10px 16px" }}>
          {
            <FormControl>
            <RadioGroup value={penalty} onChange={e => setPenalty(Number.parseFloat(e.target.value))}>
              {
                penalties.map((p) => <FormControlLabel
                  key={p.label}
                  value={p.value}
                  control={<Radio />}
                  label={p.label}
                />)
              }
            </RadioGroup>
          </FormControl>
          }
        </Box>
      </DialogContent>
      <Button
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            width: "100%"
          }}
          size="large"
          variant="contained"
          color="error"
          onClick={cancelDriver}
        >
          Cancel driver
      </Button>
    </Dialog>
  </>);
};

export default ActionCancel;