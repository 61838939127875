import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, NavLink  } from 'react-router-dom';
import { fetchDriverById } from '../../redux/Drivers/actions';
import { getCities } from '../../redux/Cities/actions';
import { parseDateCreated } from '../../helpers/utils/parser';
import {
    Box,
    Stack,
    Paper,
    Typography,
    LinearProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
 } from '../../ui/core';
 import { scrollX } from '../../ui/styles';
import ExpandMoreIcon from '../../ui/icons/ExpandMoreIcon';
import ChevronLeftIcon from '../../ui/icons/ChevronLeftIcon';
import ChevronRightIcon from '../../ui/icons/ChevronRightIcon';
import DriverInfoForm from '../../components/widgets/DriversApproval/DriverInfoForm';
import DriverDocumentsForm from '../../components/widgets/DriversApproval/DriverDocumentsForm';
import DriverStatusField from '../../components/widgets/DriversApproval/DriverStatusField';
import DriverTypeField from '../../components/widgets/DriversApproval/DriverTypeField';
import DriverCityField from '../../components/widgets/DriversApproval/DriverCityField';
import DriverScreeningLink from '../../components/widgets/DriversApproval/DriverScreeningLink';

const style = {
  container: {
    height: 'calc(100vh - 148px)',
    position: 'relative',
  },
  heading: {
    padding: '20px 32px',
    ...scrollX,
  },
  documents: {
    padding: '0px 32px',
  },
  pagination: {
    position: 'absolute',
    right: 0,
    bottom: '-24px',
    marginTop: 0,
    'a': {
      display: 'flex',
    }
  }
};

const ApprovalFunnel = () => {
  const rootRef = useRef();
  const driver = useSelector((state) => state.drivers.selectedDriver);
  const drivers = useSelector((state) => state.drivers.all.data);
  const { id } = useParams();
  const isDriverLoaded = !!(driver && driver.id);
  const dispatch = useDispatch();

  const [section, setSection] = useState(undefined);
  const [docUploads, setDocUploads] = useState({ files: {} });

  useEffect(() => {
    getCities()(dispatch);
    fetchDriverById(id)(dispatch);
  }, [id, dispatch]);

  if (!isDriverLoaded) {
    return (<Stack sx={style.container} alignItems="center">
        <LinearProgress sx={{ width: '300px', marginTop: '300px' }} />
    </Stack>);
  }

  const prevDriver = drivers[drivers.findIndex((d) => d.driver_id === driver.driver_id) - 1];
  const nextDriver = drivers[drivers.findIndex((d) => d.driver_id === driver.driver_id) + 1];

  const driverEverApproved = driver.driverApplicationStatusUpdates.some(item => item.status === "approved")

  return  <Stack ref={rootRef} sx={style.container} spacing={2}>
    <Box xs={12}>
        <Paper variant="outlined">
          <Stack sx={style.heading} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="h1" noWrap>
                    {`${driver.firstName || ""} ${driver.lastName || ""}`}
                    </Typography>
                  <DriverTypeField driver={driver} />
                  <DriverCityField driver={driver} />
                  <DriverStatusField driver={driver} />
                  <DriverScreeningLink driver={driver} />
              </Stack>
              <Typography
                sx={{ whiteSpace: 'nowrap' }}
                color="text.secondary"
              >
                {driverEverApproved && <i>Verified</i>}&nbsp;
                Applied { parseDateCreated(driver.driver_id) }
              </Typography>
          </Stack>
        </Paper>
    </Box>
    <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ flex: 1 }}>
      <Paper variant="outlined" sx={{ flex: 1 }}>
          <DriverInfoForm
            driver={driver}
            uploads={docUploads}
            setUploads={setDocUploads}
            onSectionFocus={setSection}
          />
        </Paper>
      <Box sx={{ position: 'relative', flex: 1, maxHeight: 'calc(100vh - 300px)' }}>
        <Paper variant="outlined" sx={style.documents}>
          <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} tabIndex={-1}>
                  <Typography variant="h2">Documents</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: '0 0 24px 0' }}>
                  <DriverDocumentsForm
                    section={section}
                    driver={driver}
                    uploads={docUploads}
                    setUploads={setDocUploads}
                  />
              </AccordionDetails>
          </Accordion>
        </Paper>
      </Box>
    </Stack>
    {
      (prevDriver || nextDriver) && (<Stack direction="row" justifyContent="flex-end" spacing={4} sx={style.pagination}>
      {
        prevDriver ? (
        <NavLink to={`/drivers-approval/funnel/${prevDriver.driver_id}`}>
          <ChevronLeftIcon sx={{ fontSize: 24 }}/>
          <Typography>
            {`${prevDriver.firstName || ""} ${prevDriver.lastName || ""}`}
          </Typography>
        </NavLink>
      ) : <span></span>
      }
      <Typography color="text.secondary">
        {`${driver.firstName || ""} ${driver.lastName || ""}`}
      </Typography>
      {
        nextDriver ? (
        <NavLink to={`/drivers-approval/funnel/${nextDriver.driver_id}`}>
          <Typography>
            {`${nextDriver.firstName || ""} ${nextDriver.lastName || ""}`}
          </Typography>
          <ChevronRightIcon sx={{ fontSize: 24 }} />
        </NavLink>
        ) : <span></span>
      }
    </Stack>)
    }
  </Stack>;
};

export default ApprovalFunnel;
