import React, { useEffect } from 'react';
import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from '../../layouts/DashoardLayout';
import PrivateRoute from '../../components/shared/PrivateRoute';
import DriversTriage from './DriversTriage';
import ApprovalFunnel from './ApprovalFunnel';
import DriversSearch from '../../components/widgets/DriversApproval/DriversSearch';
import { Avatar, Typography } from '../../ui/core';
import { useQuery } from '../../hooks';
import { fetchDriversCountByStatus } from '../../redux/Drivers/actions';

import { DriverStatusOptions } from '../../models/Drivers';

const DriversApproval = () => {
  const { homeCity } = useQuery();
  const dispatch = useDispatch();
  const countPerStatus = useSelector((state) => state.drivers.countPerStatus);

  useEffect(() => {
    fetchDriversCountByStatus()(dispatch);
  }, [dispatch]);

  const navigation = [
    {
      label: "Trips",
      link: "/trips/daily",
    },
    {
      label: "Users",
      link: "/users",
    },
    {
      label: "Driver Approvals",
      link: "/drivers-approval/drivers-triage",
      sublinks: DriverStatusOptions.map(({ label, value }) => ({
        label: <>
          <span>{label}</span>
          <span>{countPerStatus[value] !== null ? `(${countPerStatus[value]})` : ''}</span>
        </>,
        link: `/drivers-approval/drivers-triage/${value}?page=1${homeCity ? `&homeCity=${homeCity}` : ''}`
      })),
    },
    {
      label: "Stations",
      link: "/stations",
    },
  ]

  return (
    <DashboardLayout
      title="Drivers"
      avatar={<Avatar sx={{ backgroundColor: '#FF6837' }} variant="rounded">
        <Typography sx={{ color: '#86371E' }} variant="pageAvatar">D</Typography>
      </Avatar>}
      navigation={navigation}
      appBarContent={<DriversSearch />}
    >
      <Switch>
        <PrivateRoute
          path="/drivers-approval/drivers-triage/:driverApplicationStatus"
          component={DriversTriage}/>
        <PrivateRoute path="/drivers-approval/funnel/:id" component={ApprovalFunnel}/>
        <PrivateRoute component={DriversTriage}/>
      </Switch>
    </DashboardLayout>
  );
}

export default DriversApproval;